import Terminal from "../about/Terminal";
import React, {useState, useEffect} from 'react';
import {Box} from "@mui/material";
import axios from 'axios';
import "./styles.css";
import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';

export default function Blog() {

    const [title, setTitle] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        axios.get('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40victorfdelima',{
        })
        .then(response => {
          setTitle(response.data.items)
         }     )
      },[])
    
    function blogs() {
        return (
            <div className="App">
              <h1 className="blogger">
                Posts Medium
              </h1>
              <ul>
                {title && title.map((item,i) => {
                  return (
                    <div key={i}>
                    <p><span>{item.title}</span></p>
                      <li className="lipost">{t('Author')}: {item.author}</li>
                      <a href={item.link}>{t('seePost')}</a>
                      <p></p>

                    </div>)})
                }
              </ul>       
            </div>
          );
        }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
        <Terminal text={blogs()}/>
    </Box>
    );
};