import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flag } from 'semantic-ui-react'
import Styles from './styles.css'

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <a className={Styles} onClick={() => changeLanguage('pt')}><Flag name='br' /></a>
      <a className={Styles} onClick={() => changeLanguage('en')}><Flag name='us' /></a>
    </div>
  );
}

export default LanguageSwitcher;
