import self from "../img/self.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    hobbiess: "hobbies/interesses",
    studyes: "Estudando muito",
    Pro: "Pro em",
    skillsTools: "skills/Ferrmentas",
    sobre: "sobre",
    hey: "Hey!, my name is",
    firstName: "Victor",
    lastName: "Ferreira",
    position: "XGH, só aceito clean-code parça",
    selfPortrait: self, 
    gradient: `-webkit-linear-gradient(135deg, ${colors})`,
    baseColor: colors[0],
    miniBio: [ 
        {
            emoji: '☕',
            text: 'Viciado em café'
        },
        {
            emoji: '🌎',
            text: 'Brasileiro viajante'
        },
        {
            emoji: "💼",
            text: "Software Engineer"
        },
        {
            link: "http://mailto:contato@victorferreira.dev.br",
            emoji: "📧",
            text: "contato@victorferreira.dev.br"
        }
    ],
    socials: [
        {
            link: "https://api.whatsapp.com/send?phone=5531991060445",
            icon: "fa fa-whatsapp",
            label: 'whatsapp'
        },
        {
            link: "https://instagram.com/victorferreira020",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://medium.com/@victorfdelima",
            icon: 'fa fa-medium',
            label: 'facebook'
        },
        {
            link: "https://github.com/victorfdelima",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://linkedin.com/in/victorferreiradelima",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Já deve saber meu nome por conta desse console aqui, definitivamente sou formado em Ciência da Computação com pós em Engenharia de Software, gosto de viajar, sair para jantar ('trabaiamos') para isso. Você pode saber mais sobre eu mais abaixo",
    skills:
        {
            proficientWith: ['javascript', 'react', 'git', 'github', 'nodejs', 'html5', 'css3', 'C# .Net'],
            exposedTo: ['typescript', 'solid', 'design patterns']
        }
    ,
    hobbies: [
        {
            label: 'ler sobre Clean-Code',
            emoji: '📖'
        },
        {
            label: 'Academia',
            emoji: '🎭'
        },
        {
            label: 'Netflix as vezes é vida',
            emoji: '🎥'
        },
        {
            label: 'Comer churrascão',
            emoji: '🌶'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
}