import React, {useState, useEffect} from 'react';
import Style from './Home.module.scss';
import me from '../../img/self.png';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import {Box} from "@mui/material";
import {info} from "../../info/Info";
import axios from 'axios'
import './styles.css'
import {IoLogoTwitter} from 'react-icons/io';
import { useTranslation } from 'react-i18next'; // Importe o useTranslation




export default function Home() {

   const { t, i18n } = useTranslation();

   const [title, setTitle] = useState([])

   const Tweet = async () => {
      await axios.get('https://twitter154.p.rapidapi.com/user/tweets',{
         params: {username: 'victorfdlima020', limit: '1'},
         headers: {
            'X-RapidAPI-Key': '45b7fc406dmsh91402ddbc81de07p19276bjsne57945549665',
            'X-RapidAPI-Host': 'twitter154.p.rapidapi.com'
         }
       })
       .then(response => {
         setTitle(response.data.results)
        })
   }

   useEffect(() => {
      Tweet()
     },[])

   return (
      <Box className="containerbox" component={'main'} display={'flex'} flexDirection={{xs: 'column', md: 'row'}} alignItems={'center'}
           justifyContent={'center'} minHeight={'calc(100vh - 175px)'}>
         <Box className={classNames(Style.avatar, Style.shadowed)} alt={'image of developer'} style={{background: info.gradient}} component={'img'} src={me} width={{xs: '35vh', md: '40vh'}}
              height={{xs: '35vh', md: '40vh'}}
              borderRadius={'50%'} p={'0.75rem'} mb={{xs: '1rem', sm: 0}} mr={{xs: 0, md: '2rem'}}/>
         <Box>
            <h1>{t('hey')} <span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>{info.firstName}</span><span className={Style.hand}>🤚</span>
            </h1>
            <h2>{t('position')}.</h2>
            <Box component={'ul'} p={'0.8rem'}>
        {info.miniBio.map((bio, index) => (
          <EmojiBullet key={index} emoji={t(bio.emoji)} text={t(bio.text)} />
        ))}
      </Box>
            <Box display={'flex'} gap={'1.5rem'} justifyContent={'flex-start'} fontSize={{xs: '2rem', md: '2.5rem'}}>
               {info.socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box>

         </Box>
      </Box>
   )
}