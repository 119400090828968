import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import {Box} from "@mui/material";
import {info} from "../../info/Info";
import { useTranslation } from 'react-i18next'; 

export default function About() {
    const firstName = info.firstName.toLowerCase()
    const { t, i18n } = useTranslation();
    function aboutMeText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cat 
             {t('sobre')}{firstName} </p>
            <p><span style={{color: info.baseColor}}>{t('sobre')}{firstName} <span
                className={Style.green}>(main)</span> $ </span>
                {t('bio')}
            </p>
        </>;
    }

    function skillsText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cd {t('skillsTools')}
            </p>
            <p><span style={{color: info.baseColor}}>{t('skillsTools')} <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p style={{color: info.baseColor}}>{t('Pro')}</p>
            <ul className={Style.skills}>
                {info.skills.proficientWith.map((proficiency, index) => <li key={index}>{proficiency}</li>)}
            </ul>
            <p style={{color: info.baseColor}}>{t('studyes')}</p>
            <ul className={Style.skills}>
                {info.skills.exposedTo.map((skill, index) => <li key={index}>{skill}</li>)}
            </ul>
        </>;
    }

    function miscText() {
      
        return (
          <>
            <p>
              <span style={{ color: info.baseColor }}>
                {firstName}{info.lastName.toLowerCase()} $
              </span> cd {t('hobbiess')}
            </p>
            <p>
              <span style={{ color: info.baseColor }}>
                {t('hobbiess')} <span className={Style.green}>(main)</span> $
              </span> ls
            </p>
            <ul>
              {info.hobbies.map((hobby, index) => (
                <li key={index}>
                  <Box component={'span'} mr={'1rem'}>{hobby.emoji}</Box>
                  {t(hobby.label)} {/* Traduza o nome do hobby */}
                </li>
              ))}
            </ul>
          </>
        );
      }
      
      return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
          <Terminal text={aboutMeText()} />
          <Terminal text={skillsText()} />
          <Terminal text={miscText()} />
        </Box>
      )
}