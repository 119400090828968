import React, {useState} from 'react';
import Style from './Navbar.module.scss';
import Toggler from "./home/Toggler";
import {Link, useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import {info} from "../info/Info";
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const links = [
    {
        name: 'Inicio',
        to: '/',
        active: 'Inicio'
    },
    {
        name: 'Sobre',
        to: '/about',
        active: 'Sobre'
    },
    {
        name: 'Blog',
        to: '/blog',
        active: 'blog'
    },
    {
        name: 'Repositórios',
        to: '/repositories',
        active: 'repositories'
    }
]

export default function Navbar({darkMode, handleClick}) {
    const location = useLocation()
    const [active, setActive] = useState(location.pathname === '/' ? 'home' : location.pathname.slice(1, location.pathname.length));
    const { t } = useTranslation();
    return (
        <Box component={'nav'} width={'100%'}>
        <Box component={'ul'} display={'flex'} justifyContent={'center'} alignItems={'center'}
             gap={{ xs: '2rem', md: '8rem' }}
             textTransform={'lowercase'} fontSize={'1rem'}>
          {links.map((link, index) => (
            <Box key={index} component={'li'} className={(link.active === active && !link.type) && Style.active}
                 sx={{ borderImageSource: info.gradient }}>
              <Link to={link.to} onClick={() => setActive(link.active)}>
                {!link.type && <p style={{ paddingBottom: '0.5rem' }}>{t(link.name)}</p>}
                {link.type && <h1>{t(link.name)}</h1>}
              </Link>
            </Box>
          ))}
          <LanguageSwitcher />
          <li>
            <Toggler darkMode={darkMode} handleClick={handleClick} />
          </li>
        </Box>
      </Box>
    )
}