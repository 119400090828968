import React from 'react'
import './styles.css';

import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';


const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const { t } = useTranslation();


    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <nav>
            <ul className='pagination'>
            {/* {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            {pgNumber}
                        </a>
                    </li>
                ))} */}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={prevPage} 
                        >
                        {t('Previous')}
                    </a>
                </li>

                <li className="page-item">
                    <a className="page-link" 
                        onClick={nextPage}
                        >
                        {t('Next')}
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination;