import Terminal from "../about/Terminal";
import React, {useState, useEffect} from 'react';
import {Box} from "@mui/material";
import axios from 'axios';
import Pagination from './pagination';
import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Repository = () =>{
  

    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(2);
    const [repo, setRepo] = useState([])
    useEffect(() => {
      const fetchRepositories = async () => {
        try {
          const response = await axios.get(' https://api.github.com/users/victorfdelima/repos', {
            headers: {
              Authorization: 'Bearer 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',
            },
          });
          setRepo(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching repositories:', error);
        }
      };
  
      fetchRepositories();
    }, []);

      const indexOfLastRecord = currentPage * recordsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      const currentRecords = repo.slice(indexOfFirstRecord, indexOfLastRecord);
      const nPages = Math.ceil(repo.length / recordsPerPage)

    function skillsText() {
        return <>
                       <div className="App">
              <h1 className="blogger">
              {t('Repositoriez')}
              </h1>
              {currentRecords && currentRecords.map((item,i) => {
                  return (
                    <div key={i}>
                    <p><span>{item.name}</span></p>
                      <li className="lipost">{item.description}</li>
                      <a href={item.url}>{t('seeRepo')}</a>
                    </div>)})  
                }
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
              {/* <ul>
              {repo && repo.map((item,i) => {
                  return (
                    <div key={i}>
                    <p><span>{item.repoName}</span></p>
                      <li className="lipost">{item.repoDesc}</li>
                      <a href={item.repoLink}>Ver repositório</a>
                    </div>)})  
                }</ul>  */}
            </div>
        </>;
    }
    return (
        <div>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
        <Terminal text={skillsText()}/>
    </Box>
    </div>
    )
}

export default Repository;